<template>
  <div>
    <b-card class="chat-widget" no-body>
      <section class="chat-app-window">
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <div class="chats">
            <div>
              <div
                class="chat-body"
                v-for="user in data_user.connection_log"
                :key="user.id"
              >
                <div
                  class="d-flex justify-content-start align-items-center mb-1"
                >
                  <b-avatar :src="user.pictrue" size="50" class="mr-1" />

                  <div class="profile-user-info">
                    <h6 class="mb-0">สาขา: {{ user.name }}</h6>
                    <small class="text-muted"> เวลา: {{ user.datetime }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vue-perfect-scrollbar>
      </section>
      <br />
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCardText,
  BImg,
  BLink,
  BRow,
  BCol,
  BAvatarGroup,
  VBTooltip,
  BFormTextarea,
  BButton,
  BFormGroup,
  BEmbed,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import api from "@/api";
export default {
  components: {
    BAvatar,
    BCard,
    BCardText,
    BButton,
    BFormTextarea,
    BImg,
    BFormGroup,
    BRow,
    BLink,
    BCol,
    BAvatarGroup,
    BEmbed,
    VuePerfectScrollbar,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      data_user: "",
      perfectScrollbarSettings: {
        maxScrollbarLength: 250,
        wheelPropagation: false,
      },
    };
  },
  mounted() {
    this.loaduser();
  },
  methods: {
    async loaduser() {
      const params = {
        user_id: localStorage.getItem("user_id"),
        store_id: localStorage.getItem("store_id"),
      };
      // console.log("params :loaddata_store_id>> ", params);
      api
        .post("get_user", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.data_user = response.data.data[0];
          this.totalRows = response.data.total;
        })
        .catch((error) => {
          // window.location.reload();
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
</style>
