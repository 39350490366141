<template>
  <div>
    <b-card class="chat-widget" no-body>
      <section class="chat-app-window">
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <div class="chats">
            <div>
              <div
                class="chat-body"
                v-for="user in data_user.connection_log"
                :key="user.id"
              >
                <div>
                  <h6 class="mb-0">ระบบปฎิบัติการ: {{ user.os }}</h6>
                  <small class="text-muted"
                    >mac: {{ user.mac || "ไม่ระบุ" }}</small
                  ><br />
                  <small class="text-muted">device: {{ user.hotspot }}</small>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </vue-perfect-scrollbar>
      </section>
      <br />
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCardText,
  BImg,
  BLink,
  BRow,
  BCol,
  BAvatarGroup,
  VBTooltip,
  BFormTextarea,
  BButton,
  BFormGroup,
  BEmbed,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  components: {
    BAvatar,
    BCard,
    BCardText,
    BButton,
    BFormTextarea,
    BImg,
    BFormGroup,
    BRow,
    BLink,
    BCol,
    BAvatarGroup,
    BEmbed,
    VuePerfectScrollbar,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      data_user: "",
      perfectScrollbarSettings: {
        maxScrollbarLength: 250,
        wheelPropagation: false,
      },
    };
  },
  mounted() {
    this.loaduser();
  },
  methods: {
    async loaduser() {
      const params = {
        user_id: localStorage.getItem("user_id"),
        store_id: localStorage.getItem("store_id"),
      };
      // console.log("params :loaddata_store_id>> ", params);
      api
        .post("get_user", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.data_user = response.data.data[0];
          this.totalRows = response.data.total;
        })
        .catch((error) => {
          // window.location.reload();
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
</style>
