<template>
  <div id="user-profile">
    <section id="profile-info">
      <b-row>
        <b-col lg="5" cols="12" order="1" order-lg="1">
          <b-card>
            <b-row>
              <b-col cols="12">
                <b-row>
                  <b-col cols="4" v-if="data_user.type !== 'form'">
                    <div align="center">
                      <b-img
                        :src="data_user.picture"
                        width="120px"
                        fluid
                        alt="profile photo"
                      />
                    </div>
                  </b-col>
                  <b-col cols="8">
                    <span>ชื่อ </span
                    ><b>{{ data_user.name || data_user.displayName }}</b>
                    <span> เพศ: </span>
                    <b v-if="data_user.gender === 'female'">ผู้หญิง</b>
                    <b v-else>ผู้ชาย</b>

                    <div>
                      <span> วันเกิด: </span> <b>{{ data_user.dob }} </b><br />
                      <span> อายุ: </span> <b>{{ data_user.age }} ปี</b>
                    </div>

                    <div>
                      <span>เบอร์โทร: </span> <b>{{ data_user.phone }}</b>
                      <br />
                      <span> อีเมล: </span><b>{{ data_user.email }}</b>
                    </div>
                    <p>
                      สมัครเข้าใช้งาน: <b>{{ data_user.adddate }}</b>
                    </p>
                  </b-col>
                </b-row>

                <hr />
              </b-col>
              <b-col ols="6">
                <b-row>
                  <b-col cols="6">
                    <div style="padding-bottom: 5px">
                      <h6 class="text-capitalize">
                        มาครั้งล่าสุด <br /><b
                          style="margin-top: 5px; display: block"
                        >
                          {{ data_response.connect_last }}</b
                        >
                      </h6>
                    </div>
                    <div style="padding-bottom: 5px">
                      <h6 class="text-capitalize">
                        ใช้บริการ WIFI ครั้งล่าสุด<br />
                        <b style="margin-top: 5px; display: block">
                          {{ data_response.day_connect_last }}</b
                        >
                      </h6>
                    </div>
                    <div style="padding-bottom: 5px">
                      <h6 class="text-capitalize">
                        สาขาที่ไปมากสุด <br /><b
                          style="margin-top: 5px; display: block"
                        >
                          สาขา{{ data_response.store_branch_max.name }}</b
                        >
                      </h6>
                    </div>
                    <div style="padding-bottom: 5px">
                      <h6 class="text-capitalize">
                        ค่าเฉลี่ยจำนวนวันที่มาใช้บริการ <br />
                        <b style="margin-top: 5px; display: block">
                          {{ data_response.day_average }} วัน</b
                        >
                      </h6>
                    </div>
                    <div style="padding-bottom: 5px">
                      <h6 class="text-capitalize">
                        วันที่มาใช้บ่อยสุด <br>
                        <b style="margin-top: 5px; display: block">
                          {{
                            data_response.day_last[0].day
                              ? data_response.day_last[0].day
                              : "N/A"
                          }}</b
                        >
                      </h6>
                    </div>
                  </b-col>

                  <b-col cols="6">
                    <div style="padding-bottom: 5px">
                      <h6 class="text-capitalize">
                        จำนวนสาขาที่ไปใช้บริการ <br />
                        <b style="margin-top: 5px; display: block">
                          {{ data_response.count_branch }} สาขา</b
                        >
                      </h6>
                    </div>
                    <div style="padding-bottom: 5px">
                      <h6 class="text-capitalize">
                        ค่าเฉลี่ยไปใช้บริการต่อเดือน <br /><b
                          style="margin-top: 5px; display: block"
                          >N/a</b
                        >
                      </h6>
                    </div>
                    <div style="padding-bottom: 5px">
                      <h6 class="text-capitalize">
                        ค่าเฉลี่ยไปการใช้บริการต่อเดือน <br />
                        <b style="margin-top: 5px; display: block"
                          >{{ data_response.Month_last }} ครั้ง</b
                        >
                      </h6>
                    </div>
                    <div style="padding-bottom: 5px">
                      <h6 class="text-capitalize">
                        ช่วงเวลาที่มาใช้บริการ <br /><b
                          style="margin-top: 5px; display: block"
                        >
                          <span v-if="data_response.time_last[0].time === 'PM'"
                            >ช่วงบ่าย</span
                          >
                          <span v-else>ช่วงเช้า</span></b
                        >
                      </h6>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
          <b-card v-if="data_user.type !== 'form'">
            <!-- <b-form-group label="Tag อัตโนมัติ">
              <b-form-tags v-model="value2">
                <template v-slot="{ tags, disabled }">
                  <div
                    v-if="tags.length > 0"
                    class="list-inline d-inline-block mb-1"
                  >
                    <div
                      v-for="tag in tags"
                      :key="tag"
                      class="list-inline-item"
                    >
                      <b-form-tag
                        :title="tag"
                        :disabled="disabled"
                        variant="primary"
                      >
                        {{ tag }}
                      </b-form-tag>
                    </div>
                  </div>
                </template>
              </b-form-tags>
            </b-form-group> -->

            <b-form-group label="เพิ่ม Tag ให้ผู้ใช้งาน">
              <b-form-tags v-model="value" no-outer-focus>
                <template #default="{ tags, disabled, addTag, removeTag }">
                  <ul
                    v-if="tags.length > 0"
                    class="list-inline d-inline-block mb-1"
                  >
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                      <b-form-tag
                        :title="tag"
                        :disabled="disabled"
                        variant="primary"
                        @remove="removeTag(tag)"
                      >
                        {{ tag }}
                      </b-form-tag>
                    </li>
                  </ul>

                  <b-dropdown
                    size="sm"
                    variant="outline-secondary"
                    block
                    menu-class="w-100"
                    v-if="value.length < 5"
                  >
                    <template #button-content>
                      <feather-icon icon="LinkIcon" />
                      <span class="ml-25">Choose tags</span>
                    </template>
                    <b-dropdown-form @submit.stop.prevent="() => {}">
                      <b-form-group
                        label-for="tag-search-input"
                        label="Search tags"
                        label-cols-md="auto"
                        class="mb-0"
                        label-size="sm"
                        :description="searchDesc"
                        :disabled="disabled"
                      >
                        <b-form-input
                          id="tag-search-input"
                          v-model="search"
                          type="search"
                          size="sm"
                          autocomplete="off"
                        />
                      </b-form-group>
                    </b-dropdown-form>
                    <b-dropdown-divider />
                    <div class="demo-inline-spacing" style="padding-left: 10px">
                      <b-badge
                        v-for="option in availableOptions"
                        :key="option"
                        @click="onOptionClick({ option, addTag })"
                        style="padding-top: 5px"
                      >
                        {{ option }}
                      </b-badge>
                    </div>
                    <b-dropdown-text v-if="availableOptions.length === 0">
                      There are no tags available to select
                    </b-dropdown-text>
                  </b-dropdown>
                </template>
              </b-form-tags>
            </b-form-group>
          </b-card>
        </b-col>
        <b-col lg="7" cols="12" order="2" order-lg="2">
          <b-tabs>
            <b-tab active>
              <template #title>
                <feather-icon icon="HomeIcon" />
                <span>Overview</span>
              </template>
              <Overview></Overview>
            </b-tab>
            <!-- <b-tab >
                <template #title>
                  <feather-icon icon="MapPinIcon" />
                  <span>Venue</span>
                </template>
                <Venue ></Venue>
              </b-tab> -->
            <b-tab>
              <template #title>
                <feather-icon icon="CpuIcon" />
                <span>Connections</span>
              </template>
              <Connections></Connections>
              <!-- {{data_user}} -->
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="HardDriveIcon" />
                <span>Devices</span>
              </template>

              <Devices></Devices>
            </b-tab>
            <!-- <b-tab>
                <template #title>
                  <feather-icon icon="ShoppingBagIcon" />
                  <span>Marketing</span>
                </template>
                แล้วพบกัน!!! เร็วๆนี้ ⭐️ ⭐️ ⭐️
              </b-tab>
              <b-tab>
                <template #title>
                  <feather-icon icon="DollarSignIcon" />
                  <span>Paid Sessions</span>
                </template>
                แล้วพบกัน!!! เร็วๆนี้ ⭐️ ⭐️ ⭐️ 
              </b-tab>-->
          </b-tabs>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
  BTab,
  BCardText,
  BAvatar,
  BFormTextarea,
  BBadge,
  BFormTags,
  BFormGroup,
  BDropdown,
  BDropdownForm,
  BFormInput,
  BDropdownDivider,
  BDropdownItem,
  BFormTag,
  BDropdownText,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import Overview from "./Overview.vue";
// import Venue from "./Venue.vue";
import Connections from "./connections.vue";
import Devices from "./Devices.vue";
import api from "@/api";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BTabs,
    BNavItem,
    BButton,
    BTab,
    BCardText,
    BAvatar,
    BFormTextarea,
    BBadge,
    BFormTags,
    BFormGroup,
    BDropdown,
    BDropdownForm,
    BFormInput,
    BDropdownDivider,
    BDropdownItem,
    BFormTag,
    BDropdownText,
    Overview,
    // Venue,
    Connections,
    Devices,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data_user: "",
      data_response: "",
      options: [],
      search: "",
      type: "",
      user_id: "",
      value: [],
      value2: [],
    };
  },
  mounted() {
    this.loaduser();
  },
  computed: {
    criteria() {
      return this.search.trim().toLowerCase();
    },
    availableOptions() {
      const { criteria } = this;
      const options = this.options.filter(
        (opt) => this.value.indexOf(opt) === -1
      );
      if (criteria) {
        return options.filter(
          (opt) => opt.toLowerCase().indexOf(criteria) > -1
        );
      }
      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return "There are no tags matching your search criteria";
      }
      return "";
    },
  },
  watch: {
    value: {
      handler: function (val, oldVal) {
        this.save(val);
        console.log(" this.value :>> ", this.value.length);
      },
      deep: true,
    },
  },
  methods: {
    async loaduser() {
      const params = {
        user_id: localStorage.getItem("user_id"),
        store_id: localStorage.getItem("store_id"),
      };
      // console.log("params :loaddata_store_id>> ", params);
      api
        .post("get_user", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.data_user = response.data.data[0];
          this.totalRows = response.data.total;
          this.data_response = response.data;
          this.value = this.data_user.tag;
          if (!this.value) {
            this.value.push("ไม่พบแทก");
          }
          this.user_id = this.data_user.id;
          // console.log("this.data_user :>> ", this.data_user);
          // console.log("this.user_id :>> ", this.user_id);
          this.value2 = this.data_user.tag_auto.split(",");
          //  console.log(' ', this.data_user.tag_auto.split(","));

          for (let value of this.value2) {
            console.log("value :>> ", value);
            this.value.push(value);
          }
          for (let item of response.data.TBtag) {
            this.options.push(item.name);
          }
        })
        .catch((error) => {
          // window.location.reload();
        });
    },
    save(array) {
      let formData = new FormData();
      formData.append("user_id", localStorage.getItem("user_id"));
      formData.append("array_tag", array);
      api
        .post("set_user_tag", formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
        });
    },
    onOptionClick({ option, addTag }) {
      addTag(option);
      this.search = "";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";

b {
  font-size: 15px;
  color: rgba(72, 71, 71, 0.796);
}
h6 {
  font-size: 10px;
}
</style>
