<template>
  <div>
    <br />
    <b-row>
      <b-col cols="4" xl="4" md="12">
        <b-card>
          <statistic-card-vertical
            icon="MessageSquareIcon"
            :statistic="data_user.store_branch_total"
            statistic-title="เข้าเยี่ยม"
            color="info"
          />
        </b-card>
      </b-col>
      <b-col cols="8" xl="8" md="12">
        <b-card>
          เข้าเยี่ยมล่าสุด {{ data_user.connection_log[0].name }} <br />
          <small class="text-muted">
            เวลา: {{ data_user.connection_log[0].datetime }}</small
          >
          <gmap-map
            :zoom="14"
            :center="center"
            style="width: 100%; height: 140px"
            @click="handleMapClick"
          >
            <gmap-marker :position="position"> </gmap-marker>
          </gmap-map>
        </b-card>
      </b-col>

      <b-col cols="4" xl="4" md="12">
        <statistic-card-vertical
          icon="RadioIcon"
          :statistic="data_user.connection_total"
          statistic-title="เชื่อมต่อ"
          color="danger"
        />
      </b-col>
      <b-col cols="4" xl="4" md="12">
        <statistic-card-vertical
          icon="Share2Icon"
          statistic="2"
          statistic-title="Device"
          color="info"
        />
      </b-col>
    </b-row>

    <!-- <b-card v-if="type !== 'form'">
     
        <b-form-group label="Tag อัตโนมัติ">
          <b-form-tags v-model="value2">
            <template v-slot="{ tags, disabled }">
              <div
                v-if="tags.length > 0"
                class="list-inline d-inline-block mb-1"
              >
                <div v-for="tag in tags" :key="tag" class="list-inline-item">
                  <b-form-tag
                    :title="tag"
                    :disabled="disabled"
                    variant="primary"
                  >
                    {{ tag }}
                  </b-form-tag>
                </div>
              </div>
            </template>
          </b-form-tags>
        </b-form-group>
     
        <b-form-group label="เพิ่ม Tag ให้ผู้ใช้งาน">
          <b-form-tags v-model="value" no-outer-focus>
            <template #default="{ tags, disabled, addTag, removeTag }">
              <ul
                v-if="tags.length > 0"
                class="list-inline d-inline-block mb-1"
              >
                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                  <b-form-tag
                    :title="tag"
                    :disabled="disabled"
                    variant="primary"
                    @remove="removeTag(tag)"
                  >
                    {{ tag }}
                  </b-form-tag>
                </li>
              </ul>

              <b-dropdown
                size="sm"
                variant="outline-secondary"
                block
                menu-class="w-100"
              >
                <template #button-content>
                  <feather-icon icon="LinkIcon" />
                  <span class="ml-25">Choose tags</span>
                </template>
                <b-dropdown-form @submit.stop.prevent="() => {}">
                  <b-form-group
                    label-for="tag-search-input"
                    label="Search tags"
                    label-cols-md="auto"
                    class="mb-0"
                    label-size="sm"
                    :description="searchDesc"
                    :disabled="disabled"
                  >
                    <b-form-input
                      id="tag-search-input"
                      v-model="search"
                      type="search"
                      size="sm"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-dropdown-form>
                <b-dropdown-divider />
                <div class="demo-inline-spacing" style="padding-left: 10px">
                  <b-badge
                    v-for="option in availableOptions"
                    :key="option"
                    @click="onOptionClick({ option, addTag })"
                    style="padding-top: 5px"
                  >
                    {{ option }}
                  </b-badge>
                </div>
                <b-dropdown-text v-if="availableOptions.length === 0">
                  There are no tags available to select
                </b-dropdown-text>
              </b-dropdown>
            </template>
          </b-form-tags>
        </b-form-group>
     
    </b-card> -->
  </div>
</template>

<script>
import {
  BFormTags,
  BFormGroup,
  BDropdown,
  BDropdownForm,
  BFormInput,
  BDropdownDivider,
  BDropdownItem,
  BFormTag,
  BDropdownText,
  BCardText,
  BAvatar,
  BCard,
  BButton,
  BFormTextarea,
  BImg,
  BRow,
  BLink,
  BCol,
  BAvatarGroup,
  BEmbed,
  BBadge,
} from "bootstrap-vue";
import { codeAdvanceCustom } from "./code";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import { gmapApi } from "vue2-google-maps";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
export default {
  components: {
    BFormTags,
    BFormGroup,
    BDropdown,
    BDropdownForm,
    BFormInput,
    BDropdownDivider,
    BCardText,
    BDropdownItem,
    BFormTag,
    BDropdownText,
    BAvatar,
    BCard,
    BButton,
    BFormTextarea,
    BImg,
    BRow,
    BLink,
    BCol,
    BAvatarGroup,
    BEmbed,
    StatisticCardVertical,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      options: [],
      search: "",
      type: "",
      user_id: "",
      value: [],
      value2: [],
      codeAdvanceCustom,
      data_user: "",
      center: { lat: 16.439688394027236, lng: 102.8289296386659 },
      position: { lat: 16.439688394027236, lng: 102.8289296386659 },
    };
  },
  computed: {
    criteria() {
      return this.search.trim().toLowerCase();
    },
    availableOptions() {
      const { criteria } = this;
      const options = this.options.filter(
        (opt) => this.value.indexOf(opt) === -1
      );
      if (criteria) {
        return options.filter(
          (opt) => opt.toLowerCase().indexOf(criteria) > -1
        );
      }
      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return "There are no tags matching your search criteria";
      }
      return "";
    },
  },
  watch: {
    value: {
      handler: function (val, oldVal) {
        this.save(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.loaduser();
  },
  methods: {
    save(array) {
      let formData = new FormData();
      formData.append("user_id", this.user_id);
      formData.append("array_tag", array);
      api
        .post("set_user_tag", formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // console.log(response.data);
        });
    },
    onOptionClick({ option, addTag }) {
      addTag(option);
      this.search = "";
    },
    async loaduser() {
      const params = {
        user_id: localStorage.getItem("user_id"),
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("get_user", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // console.log("get_user = ", response.data);
          this.data_user = response.data.data[0];
          this.type = response.data.data[0].type;
          this.totalRows = response.data.total;
          // console.log("over", this.data_user);
          this.position.lat = this.data_user.connection_log[0].lat;
          this.position.lng = this.data_user.connection_log[0].lng;
          this.center.lat = this.data_user.connection_log[0].lat;
          this.center.lng = this.data_user.connection_log[0].lng;
          this.value = this.data_user.tag;
          if (!this.value) {
            this.value.push("ไม่พบแทก");
          }
          this.user_id = this.data_user.id;
          // console.log("this.data_user :>> ", this.data_user);
          // console.log("this.user_id :>> ", this.user_id);
          this.value2 = this.data_user.tag_auto.split(",");
          for (let item of response.data.TBtag) {
            this.options.push(item.name);
          }
        })
        .catch((error) => {
          // window.location.reload();
        });
    },
  },
};
</script>
